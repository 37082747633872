import React, {Component} from 'react';

class DetectAdBlock extends Component {
  state = {
    adBlockDetected: false
  }

  constructor(props: any) {
    super(props);
    this.detectAdBlocker = this.detectAdBlocker.bind(this);
  }

  componentDidMount() {
    this.detectAdBlocker();
  }

  detectAdBlocker() {
    const head = document.getElementsByTagName('head')[0];

    const noAdBlockDetected = () => {
      this.setState({
        adBlockDetected: false
      });
    };

    const adBlockDetected = () => {
      this.setState({
        adBlockDetected: true
      });
    };

    // clean up stale bait
    const oldScript =
      document.getElementById('adblock-detection');
    if (oldScript) {
      head.removeChild(oldScript);
    }

    // we will dynamically generate some 'bait'.
    const script = document.createElement('script');
    script.id = 'adblock-detection';
    script.type = 'text/javascript';
    script.src = '/pagead.js'; // changed name of bait; seems ads.js was considered safe.
    script.onload = noAdBlockDetected;
    script.onerror = adBlockDetected;
    head.appendChild(script);
  }

  noticeContentJSX() {
    return (
      <div id="adblock-notice" style={{
        position: 'absolute',
        zIndex: 1000,
        top: 0,
        left: 0,
        right: 0,
        height: 400,
        backgroundColor: '#f4282d',
        textAlign: 'center',
        lineHeight: '400px',
        fontSize: '16px',
        fontWeight: 'bold',
        color: 'white'
      }}>
        We need GA in order to measure Agorapulse.dev usage, please disable your Ad blocker :)
      </div>
    );
  }

  render() {
    return (
      <div id="adblock-wrapper">
        {this.state.adBlockDetected
          ? this.noticeContentJSX()
          : ''
        }
      </div>
    )
  }
}

export default DetectAdBlock;