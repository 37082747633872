import { Config } from '@backstage/config';
import {
  FetchApi,
} from '@backstage/core-plugin-api';
import { ResponseError } from '@backstage/errors';
import {MfeApi} from "./api";

export class MfeClient implements MfeApi {

  public configApi: Config;
  private fetchApi: FetchApi;

  constructor(options: {
    configApi: Config;
    fetchApi: FetchApi;
  }) {
    this.configApi = options.configApi;
    this.fetchApi = options.fetchApi;
  }

  async getApiOrigin(): Promise<string> {
    return await this.configApi.getString('backend.baseUrl');
  }

  async getDependencies(
    mfeName: string,
    env: string
  ): Promise<string> {

    const apiOrigin = await this.getApiOrigin();
    const requestUrl = `${apiOrigin}/api/proxy/docs-agorapulse-dev/frontend/mfe/${mfeName}/${env}/dependencies.json`;
    const request = await this.fetchApi.fetch(`${requestUrl}`);
    if (!request.ok) {
      throw await ResponseError.fromResponse(request);
    }

    return await request.text();
  }
}
