import { createPlugin, createRoutableExtension } from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const agorapulseWebpackBundleAnalyzerPlugin = createPlugin({
  id: 'agorapulse-webpack-bundle-analyzer',
  routes: {
    root: rootRouteRef,
  },
});

export const AgorapulseWebpackBundleAnalyzerPage = agorapulseWebpackBundleAnalyzerPlugin.provide(
  createRoutableExtension({
    name: 'AgorapulseWebpackBundleAnalyzerPage',
    component: () =>
      import('./components/WebpackBundleAnalyzerGraphWidget').then(m => m.WebpackBundleAnalyzerGraphWidget),
    mountPoint: rootRouteRef,
  }),
);
