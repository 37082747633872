import { useEntity } from '@backstage/plugin-catalog-react';
import React from 'react';
import { agorapulseAwsDiagramPlugin } from './plugin';
import {
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import {rootRouteRef} from "./routes";

export const EntityAwsDiagramContent = agorapulseAwsDiagramPlugin.provide(
  createRoutableExtension({
    name: 'EntityAwsDiagramContent',
    mountPoint: rootRouteRef,
    component: () =>
      import('./components/AwsDiagramWidget').then(
        ({ AwsDiagramWidget }) => {
          const AwsDiagramPage = () => {
            const { entity } = useEntity();
            return <AwsDiagramWidget entity={entity} />;
          };
          return AwsDiagramPage;
        },
      ),
  }),
);
