import React from 'react';
import {IconButton, makeStyles} from '@material-ui/core';
import {Content, Link, Page} from "@backstage/core-components";
import {
  Header
} from '@backstage/core-components';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles({
  pageContainer: {
    display: "flex",
  },
  header: {
    position: 'absolute',
    top: 0,
    width: "auto",
    right: "24px",
    height: "24px",
    zIndex: 1000,
    lineHeight: "24px",
  },
  address: {
    marginTop: 12,
    backgroundColor: '#f8f8f7',
    borderRadius: 8,
    padding: '0 12px',
    paddingRight: "48px",
    fontWeight: "bold",
  },
  edit: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  content: {
    backgroundColor: 'white',
    margin: 0,
    padding: 0,
  },
  iframe: {
    width: "100%",
    height: "100%",
    border: 'none',
    margin: 0,
    padding: 0,
  },
});
export const EmbedPage = ({url, editUrl, headerEnabled}: { url: string; editUrl: string; headerEnabled: boolean; }) => {
  const classes = useStyles();

  return (<Page themeId="agorapulse">
    {headerEnabled &&
    <Header title=''/>
    }
    <Content className={classes.content}>
      <iframe id="embed" className={classes.iframe} src={url}>&nbsp;</iframe>
    </Content>
    {headerEnabled &&
    <div className={classes.header}>
        <div className={classes.address}>
            <a target='_blank' href={url}>{url}</a>
        </div>
        <div className={classes.edit}>
            <IconButton
                component={Link}
                aria-label="Edit"
                title="Edit"
                to={editUrl}
            >
                <EditIcon/>
            </IconButton>
        </div>
    </div>
    }
  </Page>);
};
