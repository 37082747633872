import {
  RadarEntry,
  RadarQuadrant,
  RadarRing,
  TechRadarApi,
  TechRadarLoaderResponse
} from "@backstage/plugin-tech-radar";

const rings = new Array<RadarRing>();
rings.push({ id: 'use', name: 'USE', color: '#93c47d' });
rings.push({ id: 'trial', name: 'TRIAL', color: '#93d2c2' });
rings.push({ id: 'assess', name: 'ASSESS', color: '#fbdb84' });
rings.push({ id: 'retire', name: 'RETIRE', color: '#efafa9' });

const quadrants = new Array<RadarQuadrant>();
quadrants.push({ id: 'infrastructure', name: 'Infrastructure' });
quadrants.push({ id: 'frameworks', name: 'Frameworks' });
quadrants.push({ id: 'languages', name: 'Languages' });
quadrants.push({ id: 'process', name: 'Process' });

const entries = new Array<RadarEntry>();
entries.push({
  timeline: [
    {
      moved: -1,
      ringId: 'use',
      date: new Date('2021-12-01'),
      description: '',
    },
  ],
  url: '#',
  key: 'java',
  id: 'java',
  title: 'Java',
  quadrant: 'languages',
  description: '',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-01-01'),
      description: '',
    },
  ],
  url: '#',
  key: 'groovy',
  id: 'groovy',
  title: 'Groovy',
  quadrant: 'languages',
  description: '',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2021-01-01'),
      description: '',
    },
  ],
  url: '#',
  key: 'typescript',
  id: 'typescript',
  title: 'Typescript',
  quadrant: 'languages',
  description: '',
});
entries.push({
  timeline: [
    {
      moved: 1,
      ringId: 'use',
      date: new Date('2021-01-01'),
      description: '',
    },
  ],
  url: 'https://micronaut.io/',
  key: 'micronaut',
  id: 'micronaut',
  title: 'Micronaut',
  quadrant: 'frameworks',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2022-02-25'),
    },
  ],
  url: 'https://angular.io/',
  key: 'angular',
  id: 'angular',
  title: 'Angular',
  quadrant: 'frameworks',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2022-02-25'),
    },
  ],
  url: 'https://www.cypress.io/',
  key: 'cypress',
  id: 'cypress',
  title: 'Cypress',
  quadrant: 'frameworks',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2022-02-25'),
    },
  ],
  url: 'https://spockframework.org/',
  key: 'spock',
  id: 'spock',
  title: 'Spock',
  quadrant: 'frameworks',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2022-02-25'),
    },
  ],
  url: 'https://agorapulse.github.io/dru/',
  key: 'gru-dru',
  id: 'gru-dru',
  title: 'Gru/Dru',
  quadrant: 'frameworks',
});
entries.push({
  timeline: [
    {
      moved: -1,
      ringId: 'retire',
      date: new Date('2022-01-01'),
    },
  ],
  url: 'https://grails.org/',
  key: 'grails',
  id: 'grails',
  title: 'Grails',
  quadrant: 'frameworks',
});
entries.push({
  timeline: [
    {
      moved: -1,
      ringId: 'retire',
      date: new Date('2022-01-01'),
    },
  ],
  url: 'https://momentjs.com/',
  key: 'momentjs',
  id: 'momentjs',
  title: 'Moment JS',
  quadrant: 'frameworks',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2022-02-25'),
    },
  ],
  url: '#',
  key: 'code-reviews',
  id: 'code-reviews',
  title: 'Code Reviews',
  quadrant: 'process',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'use',
      date: new Date('2022-02-25'),
    },
  ],
  url: '#',
  key: 'mob-programming',
  id: 'mob-programming',
  title: 'Mob Programming',
  quadrant: 'process',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'trial',
      date: new Date('2021-09-01'),
    },
  ],
  url: '#',
  key: 'adrs',
  id: 'adrs',
  title: 'Architecture-Decision-Records',
  quadrant: 'process',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'trial',
      date: new Date('2022-02-01'),
    },
  ],
  url: '#',
  key: 'continous-delivery',
  id: 'continous-delivery',
  title: 'Continuous-Delivery',
  quadrant: 'process',
});
entries.push({
  timeline: [
    {
      moved: 0,
      ringId: 'assess',
      date: new Date('2022-02-25'),
    },
  ],
  url: '#',
  key: 'docs-like-code',
  id: 'docs-like-code',
  title: 'Docs-like-code',
  quadrant: 'process',
});
entries.push({
  timeline: [
    {
      ringId: 'use',
      date: new Date('2021-01-01'),
      description: '',
    },
  ],
  url: '#',
  key: 'github-actions',
  id: 'github-actions',
  title: 'GitHub Actions',
  quadrant: 'infrastructure',
});
entries.push({
  timeline: [
    {
      ringId: 'use',
      date: new Date('2021-01-01'),
      description: '',
    },
  ],
  url: '#',
  key: 'terraform',
  id: 'terraform',
  title: 'Terraform',
  quadrant: 'infrastructure',
});
entries.push({
  timeline: [
    {
      ringId: 'use',
      date: new Date('2021-01-01'),
      description: '',
    },
  ],
  url: '#',
  key: 'aws-beanstalk',
  id: 'aws-beanstalk',
  title: 'AWS Beanstalk',
  quadrant: 'infrastructure',
});
entries.push({
  timeline: [
    {
      ringId: 'use',
      date: new Date('2021-01-01'),
      description: '',
    },
  ],
  url: '#',
  key: 'aws-lambda',
  id: 'aws-lambda',
  title: 'AWS Lambda',
  quadrant: 'infrastructure',
});
entries.push({
  timeline: [
    {
      ringId: 'assess',
      date: new Date('2021-01-01'),
      description: '',
    },
  ],
  url: '#',
  key: 'aws-batch',
  id: 'aws-batch',
  title: 'AWS Batch',
  quadrant: 'infrastructure',
});
entries.push({
  timeline: [
    {
      ringId: 'assess',
      date: new Date('2021-01-01'),
      description: '',
    },
  ],
  url: 'https://agorapulse.slite.com/app/docs/fn6MsLHtb7YajN',
  key: 'aws-ecs-fargate',
  id: 'aws-ecs-fargate',
  title: 'AWS ECS/Fargate',
  quadrant: 'infrastructure',
});


export const mock: TechRadarLoaderResponse = {
  entries,
  quadrants,
  rings,
};

export class AgorapulseTechRadar implements TechRadarApi {
  async load() {
    return mock;
  }
}