import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 44,
  },
  path: {
    fill: '#7df3e1',
  },
});
const LogoFull = () => {
  const classes = useStyles();

  return (
    <svg className={classes.svg} width="240" height="67" viewBox="0 0 240 67" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.177 36.0469C14.0317 36.0469 16.1606 35.0792 17.7089 33.0954L17.8057 33.1438V35.6114H25.0151V11.9511H17.4186V14.4187L17.3219 14.4671C15.6768 12.5317 13.3059 11.5156 10.7899 11.5156C4.69335 11.5156 0 16.5477 0 23.8054C0 31.1116 4.69335 36.0469 11.177 36.0469ZM12.7737 29.3697C9.77379 29.3697 7.69323 27.144 7.69323 23.8054C7.69323 20.4185 9.87056 18.1928 12.7737 18.1928C15.58 18.1928 17.7573 20.5636 17.7573 23.8054C17.7573 27.1924 15.4832 29.3697 12.7737 29.3697Z" fill="#FF6726"/>
      <path d="M40.0485 45.6271C49.6287 45.6271 52.8221 40.208 52.8221 32.5148V11.9511H45.2257V14.2736H45.1289C43.3387 12.4349 40.9194 11.5156 38.355 11.5156C32.4036 11.5156 27.8554 16.4993 27.8554 23.5151C27.8554 30.2407 32.4036 35.1759 38.7905 35.1759C41.5484 35.1759 43.7258 34.2082 45.3225 32.5631L45.4192 32.6115V34.7889C45.4192 37.2565 43.3387 39.2887 39.2259 39.2887C36.6131 39.2887 34.0003 38.3693 32.0166 37.2081L30.3231 43.353C33.2262 44.9497 36.2261 45.6271 40.0485 45.6271ZM40.5807 28.4504C37.726 28.4504 35.5487 26.4182 35.5487 23.37C35.5487 20.3217 37.6776 18.2412 40.5807 18.2412C43.3871 18.2412 45.5644 20.3217 45.5644 23.3216C45.5644 26.3215 43.4354 28.4504 40.5807 28.4504Z" fill="#FF6726"/>
      <path d="M68.5352 36.0469C75.9865 36.0469 81.3089 31.0632 81.3089 23.8054C81.3089 16.4993 75.9865 11.5156 68.5352 11.5156C60.8904 11.5156 55.7132 16.5961 55.7132 23.8054C55.7132 30.9664 60.8904 36.0469 68.5352 36.0469ZM68.5352 29.3697C65.5353 29.3697 63.358 26.9505 63.358 23.8054C63.358 20.5636 65.4869 18.2412 68.5352 18.2412C71.4867 18.2412 73.664 20.5153 73.664 23.8054C73.664 27.0472 71.3899 29.3697 68.5352 29.3697Z" fill="#FF6726"/>
      <path d="M84.3991 35.6114H91.9472V24.9667C91.9472 21.87 93.7858 19.5959 97.3663 19.5959C97.7534 19.5959 98.4308 19.6443 99.1565 19.6927V11.5156H98.8662C96.2534 11.5156 93.7858 12.822 92.0439 15.2413H91.9472V11.9511H84.3991V35.6114Z" fill="#FF6726"/>
      <path d="M111.164 36.0469C114.019 36.0469 116.148 35.0792 117.696 33.0954L117.793 33.1438V35.6114H125.002V11.9511H117.406V14.4187L117.309 14.4671C115.664 12.5317 113.293 11.5156 110.777 11.5156C104.68 11.5156 99.987 16.5477 99.987 23.8054C99.987 31.1116 104.68 36.0469 111.164 36.0469ZM112.761 29.3697C109.761 29.3697 107.68 27.144 107.68 23.8054C107.68 20.4185 109.858 18.1928 112.761 18.1928C115.567 18.1928 117.744 20.5636 117.744 23.8054C117.744 27.1924 115.47 29.3697 112.761 29.3697Z" fill="#FF6726"/>
      <path d="M131.462 45.1917H137.801V32.9986L137.898 32.9502C139.543 34.934 141.865 36.0469 144.817 36.0469C151.397 36.0469 156.187 31.16 156.187 23.8538C156.187 16.6929 151.397 11.6124 145.01 11.6124C142.107 11.6124 139.591 12.6769 137.898 14.6607H137.801V11.9511H131.462V45.1917ZM143.704 30.3374C140.22 30.3374 137.511 27.773 137.511 23.9022C137.511 20.0798 140.027 17.2735 143.655 17.2735C147.042 17.2735 149.752 19.8379 149.752 23.9022C149.752 27.6763 147.236 30.3374 143.704 30.3374Z" fill="white"/>
      <path d="M168.006 36.0469C170.667 36.0469 173.183 34.934 174.973 32.7567L175.07 32.8051V35.6114H181.457V11.9511H175.07V23.4668C175.07 28.015 172.602 30.3374 169.844 30.3374C166.893 30.3374 165.586 28.8859 165.586 24.5796V11.9511H159.296V26.2247C159.296 33.4341 162.877 36.0469 168.006 36.0469Z" fill="white"/>
      <path d="M186.401 35.6114H192.74V0H186.401V35.6114Z" fill="white"/>
      <path d="M205.535 36.0469C211.728 36.0469 214.679 32.8535 214.679 28.6924C214.679 23.999 211.68 22.8861 206.551 20.9991C204.228 20.1282 203.067 19.4508 203.067 18.4831C203.067 17.2251 204.325 16.7412 205.293 16.7412C207.035 16.7412 209.164 17.5154 210.954 19.2573L214.05 14.5639C211.341 12.4834 208.68 11.6124 205.438 11.6124C200.551 11.6124 196.874 14.3704 196.874 18.6283C196.874 23.2248 200.261 25.0635 204.18 26.3699C207.228 27.386 208.438 27.9182 208.438 28.9343C208.438 30.0955 207.422 30.8697 205.583 30.8697C202.97 30.8697 200.938 29.9504 199.003 28.1601L195.954 32.5632C198.519 34.7889 201.761 36.0469 205.535 36.0469Z" fill="white"/>
      <path d="M240 23.5635C240 16.6445 235.355 11.6124 228.678 11.6124C221.662 11.6124 216.678 16.5477 216.678 23.7571C216.678 31.16 221.807 36.0469 229.065 36.0469C234.339 36.0469 237.387 34.1115 239.371 31.5471L235.258 27.7247C234 29.273 232.162 30.3858 229.5 30.3858C226.307 30.3858 224.033 28.6924 223.259 25.8376H239.855C239.903 25.4506 240 24.3377 240 23.5635ZM228.629 17.1283C231.387 17.1283 233.081 18.7734 233.71 21.2894H223.307C224.033 18.5799 226.065 17.1283 228.629 17.1283Z" fill="white"/>
      <path d="M187.906 66.2608C189.327 66.2608 190.516 65.1298 190.516 63.7088C190.516 62.2878 189.327 61.1278 187.906 61.1278C186.456 61.1278 185.296 62.2878 185.296 63.7088C185.296 65.1298 186.456 66.2608 187.906 66.2608Z" fill="white"/>
      <path d="M199.319 66.2608C201.088 66.2608 202.422 65.6518 203.35 64.4918H203.408V65.9998H207.729V44.6558H203.176V53.3268H203.147C202.132 52.1668 200.682 51.5578 199.087 51.5578C195.433 51.5578 192.62 54.5738 192.62 58.9238C192.62 63.3028 195.404 66.2608 199.319 66.2608ZM200.247 62.2588C198.478 62.2588 197.202 60.9248 197.202 58.9238C197.202 56.8938 198.536 55.5598 200.247 55.5598C202.074 55.5598 203.35 56.9808 203.35 58.9238C203.35 60.9538 202.016 62.2588 200.247 62.2588Z" fill="white"/>
      <path d="M224.362 58.7498C224.362 54.5448 221.433 51.5578 217.431 51.5578C213.139 51.5578 210.123 54.6028 210.123 58.8948C210.123 63.3028 213.255 66.2608 217.547 66.2608C220.766 66.2608 222.738 65.1878 223.927 63.7088L221.317 61.0408C220.36 62.1138 219.171 62.5488 218.011 62.5778C216.271 62.5778 214.908 61.7368 214.618 60.1128H224.275C224.304 59.8228 224.362 59.1558 224.362 58.7498ZM217.373 55.1538C218.736 55.1538 219.664 56.0238 219.954 57.4158H214.647C214.937 55.9948 215.981 55.1538 217.373 55.1538Z" fill="white"/>
      <path d="M230.061 65.9998H234.208L239.718 51.8188H234.991L232.178 60.5478H232.149L229.365 51.8188H224.58L230.061 65.9998Z" fill="white"/>
    </svg>

  );
};

export default LogoFull;
