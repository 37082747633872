import React from 'react';
import Grid, {GridSize} from '@material-ui/core/Grid'
import {
  Button,
  Content,
  Page,
  InfoCard,
} from '@backstage/core-components';
import {EmbedWidget} from "@internal/plugin-agorapulse-embed/src/components/EmbedPage";
import {Typography} from "@material-ui/core";

const infoCardPadding = 20;

const Tribe = ({
                 name,
                 mdSize,
                 system,
                 backends,
                 newRelic
               }: { name: string, mdSize: GridSize, system: string, backends: string[], newRelic: string }) => {
  const teamName = (name == 'Platform') ? 'Team' : 'Tribe';
  const squadButtons = backends.map((squad: string) => {
    let name = squad.substring(squad.indexOf('-') + 1).replace('-backend', '');
    const prettyName = name[0].toUpperCase() + name.substr(1).toLowerCase();
    return (
      <Button
        to={`/catalog/default/component/${squad}`}
        color="primary" variant="outlined" style={{marginTop: 8, marginRight: 8}}>{prettyName}</Button>
    );
  });
  return (
    <Grid item xs={12} md={mdSize}>
      <InfoCard>
        <Typography variant="h5">{name}</Typography>
        <p>
          <Button
            to={`/catalog/default/group/${system}`}
            color="secondary" variant="outlined" style={{marginTop: 8, marginRight: 8}}>{teamName}</Button>
          {squadButtons}
          <Button
            to={newRelic}
            color="default" variant="outlined" style={{marginTop: 8, marginRight: 8}}>New Relic</Button>
        </p>
      </InfoCard>
    </Grid>
  )
}

const SmallMetric = ({url}: { url: string }) => {
  const newRelicSmallMetricHeight = 150;
  return (
    <Grid item xs={6} md={2}>
      <InfoCard>
        <EmbedWidget url={`${url}?height=${newRelicSmallMetricHeight}px&timepicker=false`}
                     height={newRelicSmallMetricHeight + infoCardPadding}/>
      </InfoCard>
    </Grid>
  )
}

const MediumMetric = ({url}: { url: string }) => {
  const newRelicMediumMetricHeight = 240;
  return (
    <Grid item xs={12} md={3}>
      <InfoCard>
        <EmbedWidget url={`${url}?height=${newRelicMediumMetricHeight}px&timepicker=false`}
                     height={newRelicMediumMetricHeight + infoCardPadding}/>
      </InfoCard>
    </Grid>
  )
}

export const HomePage = () => {


  return (
    <Page themeId="agorapulse">
      {/*<Header title='Dashboard'/>*/}
      <Content>
        <Grid container spacing={1}>

          <Tribe name="Analytics" mdSize={3} system="analytics-tribe"
                 backends={['analytics-report-backend', 'analytics-roi-backend']}
                 newRelic="https://one.newrelic.com/dashboards/detail/NjY4NTh8VklafERBU0hCT0FSRHxkYTo1ODEyMA"/>
          {/* Sections exported */}
          <SmallMetric url="https://chart-embed.service.newrelic.com/herald/b44dbea4-2281-4055-9c6c-d1beb7e073be"/>
          <SmallMetric url="https://chart-embed.service.newrelic.com/herald/1ceb49e4-f19b-4a6c-a03b-05e16cc2661d"/>
          <SmallMetric url="https://chart-embed.service.newrelic.com/herald/e3adc571-281a-4ea5-90ef-b3edd83c95bc"/>
          <MediumMetric url="https://chart-embed.service.newrelic.com/herald/ce0cc00b-86de-4639-a164-3ffd1576b61d"/>

          <Tribe name="Content" mdSize={2} system="content-tribe"
                 backends={['content-library-backend', 'content-publishing-backend']}
                 newRelic="https://one.newrelic.com/dashboards/detail/NjY4NTh8VklafERBU0hCT0FSRHxkYTo1NzIxNw"/>
          {/* Publishing v1 */}
          <SmallMetric url="https://chart-embed.service.newrelic.com/herald/46fe174c-bab6-4f9f-bd63-6a5d48b4f34c"/>
          {/* Publishing v2 */}
          <SmallMetric url="https://chart-embed.service.newrelic.com/herald/c566465d-10f3-4446-84b4-beeaf8208da8"/>
          {/* Publishing v1 services */}
          <MediumMetric url="https://chart-embed.service.newrelic.com/herald/14197b0a-1eb6-4959-90eb-f263cb6a725e"/>
          {/* Publishing v2 services */}
          <MediumMetric url="https://chart-embed.service.newrelic.com/herald/2670ae6e-cb0a-4bd7-95ad-87aa0c42db97"/>

          <Tribe name="Conversation" mdSize={4} system="conversation-tribe"
                 backends={['conversation-inbox-backend']}
                 newRelic="https://one.newrelic.com/dashboards/detail/NjY4NTh8VklafERBU0hCT0FSRHxkYTo1NjcwMg"/>
          {/* Reply errors */}
          <SmallMetric url="https://chart-embed.service.newrelic.com/herald/4053827d-1263-4181-9201-e670880b45cc"/>
          {/* Reply errors services */}
          <MediumMetric url="https://chart-embed.service.newrelic.com/herald/d083f4d9-8ace-4864-bc82-2447f0c94912"/>
          {/* RTUs */}
          <MediumMetric url="https://chart-embed.service.newrelic.com/herald/6c35894e-a3a7-440e-96c2-7afb9aeba9a0"/>

          <Tribe name="Organization" mdSize={4} system="organization-tribe"
                 backends={['organization-core-backend', 'organization-subscription-backend']}
                 newRelic="https://one.newrelic.com/dashboards/detail/NjY4NTh8VklafERBU0hCT0FSRHxkYTo1NzIxMg"/>
          {/* Manager sign-ins */}
          <SmallMetric url="https://chart-embed.service.newrelic.com/herald/4a47b1bb-579f-42ea-b4dc-455e655c412b"/>
          {/* Manager sign-ups */}
          <SmallMetric url="https://chart-embed.service.newrelic.com/herald/2451e6b3-17e1-4039-aed4-66992a19215a"/>
          {/* Organizations created */}
          <SmallMetric url="https://chart-embed.service.newrelic.com/herald/ad16a9ae-67d5-4f3b-a96f-89684d49e561"/>
          {/* Accounts added */}
          <SmallMetric url="https://chart-embed.service.newrelic.com/herald/794cd497-a755-43a8-a04a-6ccd11517c43"/>

          <Tribe name="Platform" mdSize={4} system="platform-team"
                 backends={['identity', 'live', 'notification']}
                 newRelic="https://one.newrelic.com/dashboards/detail/NjY4NTh8VklafERBU0hCT0FSRHxkYToxNDgxMzA3"/>
          <SmallMetric url="https://chart-embed.service.newrelic.com/herald/6eec5822-fc04-47ca-973b-2f27962ca013"/>
          <SmallMetric url="https://chart-embed.service.newrelic.com/herald/4fe548ab-d2ed-45e4-8294-efe29228e00c"/>
          <SmallMetric url="https://chart-embed.service.newrelic.com/herald/bb2685c9-21b7-4091-a36e-b305b636cdec"/>
          <SmallMetric url="https://chart-embed.service.newrelic.com/herald/9ce6ca97-7ace-4c19-9aa1-c68004750105"/>
          <Tribe name="Infra" mdSize={4} system="ops-team"
                 backends={['infra-devops', 'infra-modules','infra-provisioning', 'infra-runbooks']}
                 newRelic="https://onenr.io/08woXr3y5Rx"/>
        </Grid>
      </Content>
    </Page>
  );
};