import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 22,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg className={classes.svg} width="100" height="66" viewBox="0 0 100 66" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M29.4894 65.1052C37.0213 65.1052 42.6383 62.5521 46.7234 57.318L46.9787 57.4457V63.9563H66V1.53077H45.9575V8.04141L45.7021 8.16907C41.3617 3.06269 35.1064 0.381836 28.4681 0.381836C12.383 0.381836 0 13.6584 0 32.8074C0 52.084 12.383 65.1052 29.4894 65.1052ZM33.7021 47.4882C25.7872 47.4882 20.2979 41.6159 20.2979 32.8074C20.2979 23.8712 26.0426 17.9989 33.7021 17.9989C41.1064 17.9989 46.8511 24.2542 46.8511 32.8074C46.8511 41.7435 40.8511 47.4882 33.7021 47.4882Z" fill="#FF6726"/>
      <path d="M73.947 4.53337C73.947 6.18275 75.2598 7.56283 76.9092 7.56283C78.5585 7.56283 79.905 6.18275 79.905 4.53337C79.905 2.85033 78.5585 1.50391 76.9092 1.50391C75.2598 1.50391 73.947 2.85033 73.947 4.53337Z" fill="white"/>
      <path d="M73.947 17.7815C73.947 19.8348 74.6539 21.3832 76.0003 22.4603V22.5277H74.25L74.25 27.5431H99.0243V22.2584H88.9597V22.2247C90.3061 21.0466 91.013 19.3635 91.013 17.5122C91.013 13.271 87.5123 10.0059 82.4632 10.0059C77.3804 10.0059 73.947 13.2373 73.947 17.7815ZM78.5922 18.8586C78.5922 16.8053 80.1406 15.3243 82.4632 15.3243C84.8194 15.3243 86.3678 16.8727 86.3678 18.8586C86.3678 20.9793 84.7185 22.4603 82.4632 22.4603C80.1069 22.4603 78.5922 20.9119 78.5922 18.8586Z" fill="white"/>
      <path d="M82.6651 46.8488C87.5459 46.8488 91.013 43.4491 91.013 38.8039C91.013 33.8221 87.4786 30.3214 82.4968 30.3214C77.3804 30.3214 73.947 33.9568 73.947 38.9386C73.947 42.6749 75.1925 44.9638 76.9092 46.3439L80.0059 43.3145C78.7605 42.2037 78.2556 40.8236 78.2219 39.4771C78.2219 37.4575 79.1981 35.8754 81.0831 35.5388L81.0831 46.7478C81.4197 46.7815 82.1939 46.8488 82.6651 46.8488ZM86.8391 38.7366C86.8391 40.3187 85.8293 41.3958 84.2135 41.7324V35.5725C85.8629 35.9091 86.8391 37.1209 86.8391 38.7366Z" fill="white"/>
      <path d="M74.25 53.4642L74.25 58.2777L90.7101 64.6732V59.1865L80.5782 55.9214V55.8878L90.7101 52.6564V47.1023L74.25 53.4642Z" fill="white"/>
    </svg>
  );
};

export default LogoIcon;
