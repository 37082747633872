import {
  configApiRef,
  createApiFactory,
  createPlugin,
  createRoutableExtension,
  fetchApiRef
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import {mfeApiRef} from "./api";
import {MfeClient} from "./client";

export const agorapulseMfePlugin = createPlugin({
  id: 'agorapulse-mfe',
  apis: [
    createApiFactory({
      api: mfeApiRef,
      deps: {
        configApi: configApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ configApi, fetchApi }) =>
        new MfeClient({
          configApi,
          fetchApi,
        }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const AgorapulseMfePage = agorapulseMfePlugin.provide(
  createRoutableExtension({
    name: 'AgorapulseMfePage',
    component: () =>
      import('./components/MfeDependenciesWidget').then(m => m.MfeDependenciesWidget),
    mountPoint: rootRouteRef,
  }),
);
