import {createApiRef} from "@backstage/core-plugin-api";

export const mfeApiRef = createApiRef<MfeApi>({
  id: 'plugin.mfe.service',
});

export interface MfeApi {
  getApiOrigin(): Promise<string>;
  getDependencies(mfeName: string, env: string): Promise<string>;
}
