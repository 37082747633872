import { useEntity } from '@backstage/plugin-catalog-react';
import React from 'react';
import { agorapulseDatastudioPlugin } from './plugin';
import {
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import {rootRouteRef} from "./routes";

export const EntityDatastudioContent = agorapulseDatastudioPlugin.provide(
  createRoutableExtension({
    name: 'EntityFinopsContent',
    mountPoint: rootRouteRef,
    component: () =>
      import('./components/DatastudioWidget').then(
        ({ DatastudioWidget }) => {
          const DatastudioPage = () => {
            const { entity } = useEntity();
            return <DatastudioWidget entity={entity} />;
          };
          return DatastudioPage;
        },
      ),
  }),
);
