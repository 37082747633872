import React from 'react';
import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles({
  iframe: {
    width: "100%",
    /*height: "100%",*/
    border: 'none',
    margin: 0,
    padding: 0,
  },
});
export const EmbedWidget = ({url, height}: { url: string; height: number; }) => {
  const classes = useStyles();

  return (<iframe id="embed" className={classes.iframe} style={{ height: height }} src={url}>&nbsp;</iframe>);
};
