import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import { TechRadarPage } from '@backstage/plugin-tech-radar';
import { NewRelicPage } from '@backstage/plugin-newrelic';
import { AgorapulseEmbedPage } from '@internal/plugin-agorapulse-embed';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { AlertDisplay, OAuthRequestDialog } from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';

import { githubAuthApiRef } from '@backstage/core-plugin-api';
import { SignInProviderConfig, SignInPage } from '@backstage/core-components';
import {
  createTheme,
  genPageTheme,
  lightTheme,
  shapes,
} from '@backstage/theme';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';

const githubProvider: SignInProviderConfig = {
    id: 'github-auth-provider',
    title: 'GitHub',
    message: 'Sign in using GitHub',
    apiRef: githubAuthApiRef,
};

const agorapulseTheme = createTheme({
  palette: {
    ...lightTheme.palette,
    primary: {
      main: '#178dfe',
    },
    secondary: {
      main: '#FF560D',
    },
    error: {
      main: '#FF560D',
    },
    warning: {
      main: '#FF560D',
    },
    info: {
      main: '#61a4e4',
    },
    success: {
      main: '#45B854',
    },
    navigation: {
      background: '#344563',
      indicator: '#FF560D',
      color: '#d5d6db',
      selectedColor: '#ffffff',
    },
  },
  defaultPageTheme: 'home',
  pageTheme: {
    home: genPageTheme({ colors: ['#344563', '#61a4e4'], shape: shapes.wave }),
    documentation: genPageTheme({ colors: ['#344563', '#61a4e4'], shape: shapes.wave2 }),
    tool: genPageTheme({ colors: ['#344563', '#61a4e4'], shape: shapes.round }),
    service: genPageTheme({ colors: ['#344563', '#61a4e4'], shape: shapes.wave }),
    website: genPageTheme({ colors: ['#344563', '#61a4e4'], shape: shapes.wave }),
    library: genPageTheme({ colors: ['#344563', '#61a4e4'], shape: shapes.wave }),
    other: genPageTheme({ colors: ['#344563', '#61a4e4'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#344563', '#61a4e4'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#344563', '#61a4e4'], shape: shapes.wave }),
  },
});

const app = createApp({
  apis,
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        auto
        provider={githubProvider}
      />
    ),
  },
  themes: [
    {
      id: 'agorapulse-theme',
      title: 'Agorapulse Theme',
      variant: 'light',
      Provider: ({ children }) => (
        <ThemeProvider theme={agorapulseTheme}>
          <CssBaseline>{children}</CssBaseline>
        </ThemeProvider>
      ),
    }
  ],
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    {/*
    <Navigate key="/" to="catalog" />
    */}
    <Route path="/" element={<Navigate to="catalog" />} />
    <Route path="/home" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route
      path="/newrelic"
      element={<NewRelicPage />}
    />
    {/*
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />*/}

    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/ds-v1" element={<AgorapulseEmbedPage url='https://design.agorapulse.com' editUrl='https://github.com/agorapulse/design' headerEnabled={false} />} />
    <Route path="/ds-v2" element={<AgorapulseEmbedPage url='https://design.beta.agorapulse.com' editUrl='https://github.com/agorapulse/design' headerEnabled={false} />} />
    <Route path="/dev-env" element={<AgorapulseEmbedPage url='https://dev.agorapulse.com' editUrl='https://github.com/agorapulse/dev.agorapulse.com/tree/master/website' headerEnabled />} />
    <Route path="/incidents" element={<AgorapulseEmbedPage url='https://dev.agorapulse.com/incidents.html' editUrl='https://github.com/agorapulse/dev.agorapulse.com/blob/master/website/incidents.json' headerEnabled />} />
    <Route path="/kibana" element={<AgorapulseEmbedPage url='/api/proxy/kibana/publishing/prod' editUrl='#' headerEnabled={false} />} />
    <Route path="/oss" element={<AgorapulseEmbedPage url='https://agorapulse.github.io/agorapulse-oss/' editUrl='https://github.com/agorapulse/agorapulse-oss/tree/master/docs/guide/src/docs/asciidoc' headerEnabled />} />
    <Route path="/data-studio" element={<AgorapulseEmbedPage url='https://datastudio.google.com/embed/reporting/1jAJ1-JLPaY_SbKl53aGoWKenFF9JfAjO/page/chgL' editUrl='#' headerEnabled={false} />} />

  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);

