import { useEntity } from '@backstage/plugin-catalog-react';
import React from 'react';
import { agorapulseVisualPlanPlugin } from './plugin';
import {
  createRoutableExtension,
} from '@backstage/core-plugin-api';
import {rootRouteRef} from "./routes";

export const EntityVisualPlanContent = agorapulseVisualPlanPlugin.provide(
  createRoutableExtension({
    name: 'EntityFinopsContent',
    mountPoint: rootRouteRef,
    component: () =>
      import('./components/VisualPlanWidget').then(
        ({ VisualPlanWidget }) => {
          const VisualPlanPage = () => {
            const { entity } = useEntity();
            return <VisualPlanWidget entity={entity} />;
          };
          return VisualPlanPage;
        },
      ),
  }),
);
